import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { jobActions } from 'store/modules/job';
import { totalJobsHistory } from 'store/modules/job/selector';

export const useTotalJobsHistorySummary = () => {
  const dispatch = useDispatch();
  const options = useSelector(totalJobsHistory);

  useEffect(() => {
    dispatch(jobActions.getTotalJobsHistory());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return options;
};
