import { PATH_NAME } from 'configs';
import { GuestRouteGuard, RolePermissionRouteGuard } from 'guards';
import concat from 'lodash/concat';
import { FC, lazy } from 'react';

import { Role } from 'models/user';

import { InternalPermission, PartnerPermission, SystemPermission } from 'types/permission';

import { RedirectFirstPage } from 'components/organisms/NavMenu/RedirectFirstPage';

import Error404 from 'pages/Error404';

export interface RouteConfig {
  path: string;
  roles?: Role[];
  permissions?: SystemPermission[];
  component?: FC; // this value should be undefined when roleComponents is exist
  roleComponents?: { [key in Role]?: FC }; // using for render different role with same path
  title?: string;
  breakLineTitle?: [string, string];
  metaData?: { title: string };
  exact?: boolean;
  guard?: FC<any>;
  layout?: FC;
  disabled?: boolean;
  type?: 'all' | 'internal' | 'partner' | 'superHq';
  defaultScrollOnTop?: boolean;
  excludeMainLayout?: boolean;
}

const Demo = lazy(() => {
  if (process.env.NODE_ENV === 'development') {
    return import('pages/Demo');
  }
  return import('pages/Error404');
});

const HqAdminDashboard = lazy(() => import('pages/Dashboard/HqAdminView'));
const InternalAdminDashboard = lazy(() => import('pages/Dashboard/InternalAdminView'));
const AreaAdminViewDashboard = lazy(() => import('pages/Dashboard/AreaAdminView'));
const LocationAdminViewDashboard = lazy(() => import('pages/Dashboard/LocationAdminView'));
const Login = lazy(() => import('pages/Login'));
const Select2faMethod = lazy(() => import('pages/Select2FAMethod'));
const AuthOtpQr = lazy(() => import('pages/TwoFactorOtpQrPage'));
const AuthOtp = lazy(() => import('pages/AuthOtpPage'));
const SuccessfulLogin = lazy(() => import('pages/SuccessfulLogin'));
const Company = lazy(() => import('pages/Company'));
const CompanyNew = lazy(() => import('pages/CompanyNew'));
const CompanyEdit = lazy(() => import('pages/CompanyEdit'));
const JobTypeEdit = lazy(() => import('pages/JobTypeEdit'));
const JobTypeNew = lazy(() => import('pages/JobTypeNew'));
const JobTypeList = lazy(() => import('pages/JobType'));
const HqUserEdit = lazy(() => import('pages/HQUserEdit'));
const HqUserNew = lazy(() => import('pages/HQUserNew'));
const HqUser = lazy(() => import('pages/HQUser'));
const EducationalInstitute = lazy(() => import('pages/EducationalInstitute'));
const EducationalInstituteNew = lazy(() => import('pages/EducationalInstituteNew'));
const EducationalInstituteEdit = lazy(() => import('pages/EducationalInstituteEdit'));
const SystemLocations = lazy(() => import('pages/SystemLocations'));
const Applicant = lazy(() => import('pages/Applicant'));
const ApplicantNew = lazy(() => import('pages/ApplicantNew'));
const ApplicantEdit = lazy(() => import('pages/ApplicantEdit'));
const BillingRecord = lazy(() => import('pages/BillingRecord'));
const CompanyAssignCredit = lazy(() => import('pages/CompanyAssignCredit'));
const CompanyCreditHistory = lazy(() => import('pages/CompanyCreditHistory'));
const CompanyDebitCreditHistory = lazy(() => import('pages/CompanyDebitCreditHistory'));
const JodCreditInCompany = lazy(() => import('pages/JodCreditInCompany'));
const PaymentSummary = lazy(() => import('pages/PaymentSummary'));
const PaymentProcessed = lazy(() => import('pages/PaymentProcessed'));
const PaymentAdjustments = lazy(() => import('pages/PaymentAdjustments'));
const PaymentFuture = lazy(() => import('pages/PaymentFuture'));
const ReportList = lazy(() => import('pages/Report'));
const JobTemplateList = lazy(() => import('pages/JobTemplate'));
const JobTemplateNew = lazy(() => import('pages/JobTemplateNew'));
const JobTemplateEdit = lazy(() => import('pages/JobTemplateEdit'));
const ManagerList = lazy(() => import('pages/Manager'));
const ManagerNew = lazy(() => import('pages/ManagerNew'));
const ManagerEdit = lazy(() => import('pages/ManagerEdit'));
const SystemManager = lazy(() => import('pages/SystemManager'));
const SystemManagerNew = lazy(() => import('pages/SystemManagerNew'));
const SystemManagerEdit = lazy(() => import('pages/SystemManagerEdit'));
const AllJobPosting = lazy(() => import('pages/JobPosting'));
const SuperHQJobPosting = lazy(() => import('pages/SuperHQJobPosting'));
const JobNew = lazy(() => import('pages/JobNew'));
const JobEdit = lazy(() => import('pages/JobEdit'));
const JobCopy = lazy(() => import('pages/JobCopy'));
const JobCancel = lazy(() => import('pages/JobCancel'));
const JobBulkUpdate = lazy(() => import('pages/JobBulkUpdate'));
const CompanyLocations = lazy(() => import('pages/CompanyLocations'));
const LocationNew = lazy(() => import('pages/LocationNew'));
const LocationEdit = lazy(() => import('pages/LocationEdit'));
const JobApplicant = lazy(() => import('pages/JobApplicant'));
const LocationFeedbacks = lazy(() => import('pages/CompanyLocationFeedbacks'));
const AccountSettings = lazy(() => import('pages/AccountSettings'));
const AreaLocations = lazy(() => import('pages/AreaLocations'));
const JodCreditInArea = lazy(() => import('pages/JodCreditInArea'));
const JobHistory = lazy(() => import('pages/JobHistory'));
const HowToPost = lazy(() => import('pages/HowToPostJobs'));
const HowToSelections = lazy(() => import('pages/HowToMakeSelections'));
const HowToClock = lazy(() => import('pages/HowToClock'));
// const Notifications = lazy(() => import('pages/Notification'));
const LocationProfile = lazy(() => import('pages/LocationProfile'));
const LocationProfileFeedback = lazy(() => import('pages/LocationProfileFeedback'));
const AreaLocationFeedbacks = lazy(() => import('pages/AreaLocationFeedbacks'));
const JodCreditInLocation = lazy(() => import('pages/JodCreditInLocation'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));

const InternalUser = lazy(() => import('pages/InternalUser'));
const InternalUserNew = lazy(() => import('pages/InternalUserNew'));
const InternalUserEdit = lazy(() => import('pages/InternalUserEdit'));
const UserRole = lazy(() => import('pages/UserRole'));
const UserRoleNew = lazy(() => import('pages/UserRoleNew'));
const UserRoleEdit = lazy(() => import('pages/UserRoleEdit'));
const UserPartnerRoleEdit = lazy(() => import('pages/UserPartnerRoleEdit'));
const Permission = lazy(() => import('pages/Permission'));
const NotificationConfiguration = lazy(() => import('pages/NotificationConfiguration'));
const OtherConfiguration = lazy(() => import('pages/OtherConfiguration'));
const BadgeConfiguration = lazy(() => import('pages/BadgeConfiguration'));
const AppConfiguration = lazy(() => import('pages/AppConfiguration'));
const PartnerSignUp = lazy(() => import('pages/PartnerSignUp'));
const SuperHqToggleView = lazy(() => import('pages/Dashboard/SuperHqToggleView'));
const SuperHqUser = lazy(() => import('pages/SuperHqUser'));
const SuperHqUserEdit = lazy(() => import('pages/SuperHqUserEdit'));
const SuperHqUserNew = lazy(() => import('pages/SuperHqUserNew'));
const VerifyHqEmail = lazy(() => import('pages/VerificationPageEmail'));
const PaymentApproval = lazy(() => import('pages/PaymentApproval'));
const HqUserRole = lazy(() => import('pages/HqUserRole'));
const HqUserRoleEdit = lazy(() => import('pages/HqUserRoleEdit'));
const HqPermission = lazy(() => import('pages/HqPermission'));

const Certificate = lazy(() => import('pages/Certificate'));
const CertificateEdit = lazy(() => import('pages/CertificateEdit'));
const CertificateNew = lazy(() => import('pages/CertificateNew'));
const SlotUserList = lazy(() => import('pages/SlotUser'));
const SlotUserEdit = lazy(() => import('pages/SlotUserEdit'));

export const publicRoutes: RouteConfig[] = [
  {
    path: PATH_NAME.ERROR_404,
    exact: true,
    component: Error404,
    metaData: { title: 'title.404PageNotFound' },
    excludeMainLayout: true,
  },
  {
    path: PATH_NAME.LOGIN,
    exact: true,
    guard: GuestRouteGuard,
    component: Login,
    excludeMainLayout: true,
  },
  {
    path: PATH_NAME.PARTNER_SIGN_UP,
    exact: true,
    guard: GuestRouteGuard,
    component: PartnerSignUp,
    excludeMainLayout: true,
  },
  {
    path: PATH_NAME.FORGOT_PASSWORD,
    exact: true,
    guard: GuestRouteGuard,
    component: ForgotPassword,
    excludeMainLayout: true,
  },
  {
    path: PATH_NAME.RESET_PASSWORD,
    exact: true,
    guard: GuestRouteGuard,
    component: ResetPassword,
    excludeMainLayout: true,
  },
  {
    path: PATH_NAME.VERIFY_EMAIL,
    exact: true,
    guard: GuestRouteGuard,
    component: VerifyHqEmail,
    excludeMainLayout: true,
  },
];

export const internalRoutes: RouteConfig[] = [
  {
    path: PATH_NAME.DASHBOARD,
    exact: true,
    title: 'title.dashboard',
    component: InternalAdminDashboard,
    guard: RolePermissionRouteGuard,
    permissions: [
      InternalPermission.VIEW_COMPANY,
      InternalPermission.VIEW_HQ_USER,
      InternalPermission.VIEW_PAYMENT_FUTURE,
      InternalPermission.VIEW_PAYMENT_SUMMARY,
      InternalPermission.CHANGE_DASHBOARD_LOGO,
      InternalPermission.VIEW_JOB_TYPE,
      InternalPermission.VIEW_LOCATION,
      InternalPermission.VIEW_APPLICANT,
      InternalPermission.VIEW_EDUCATIONAL_INSTITUTE,
      InternalPermission.VIEW_CERTIFICATES,
    ],
    type: 'internal',
  },
  {
    path: PATH_NAME.COMPANY_NEW,
    component: CompanyNew,
    guard: RolePermissionRouteGuard,
    metaData: { title: 'title.newCompany' },
    title: 'title.newCompany',
    permissions: [InternalPermission.CREATE_COMPANY],
    type: 'internal',
  },
  {
    path: PATH_NAME.COMPANY_EDIT(),
    component: CompanyEdit,
    guard: RolePermissionRouteGuard,
    metaData: { title: 'title.newCompany' },
    title: 'title.editCompany',
    permissions: [InternalPermission.UPDATE_COMPANY],
    type: 'internal',
  },
  {
    path: PATH_NAME.COMPANY,
    component: Company,
    guard: RolePermissionRouteGuard,
    metaData: { title: 'title.listOfCompanies' },
    title: 'title.listOfCompanies',
    permissions: [InternalPermission.VIEW_COMPANY],
    type: 'internal',
  },
  {
    path: PATH_NAME.JOB_TYPE_NEW,
    component: JobTypeNew,
    guard: RolePermissionRouteGuard,
    title: 'title.newJobType',
    metaData: { title: 'title.newJobType' },
    permissions: [InternalPermission.CREATE_JOB_TYPE],
    type: 'internal',
  },
  {
    path: PATH_NAME.CERTIFICATE_NEW,
    component: CertificateNew,
    guard: RolePermissionRouteGuard,
    title: 'title.newCertificate',
    metaData: { title: 'title.newCertificate' },
    permissions: [InternalPermission.CREATE_CERTIFICATE],
    type: 'internal',
  },
  {
    path: PATH_NAME.CERTIFICATE_EDIT(),
    component: CertificateEdit,
    guard: RolePermissionRouteGuard,
    title: 'title.editCertificate',
    metaData: { title: 'title.editCertificate' },
    permissions: [InternalPermission.UPDATE_CERTIFICATE],
    type: 'internal',
  },
  {
    path: PATH_NAME.JOB_TYPE_EDIT(null),
    component: JobTypeEdit,
    guard: RolePermissionRouteGuard,
    title: 'title.editJobType',
    metaData: { title: 'title.editJobType' },
    permissions: [InternalPermission.UPDATE_JOB_TYPE],
    type: 'internal',
  },
  {
    path: PATH_NAME.JOB_TYPE,
    component: JobTypeList,
    guard: RolePermissionRouteGuard,
    title: 'title.listOfJobTypes',
    metaData: { title: 'title.listOfJobTypes' },
    permissions: [InternalPermission.VIEW_JOB_TYPE],
    type: 'internal',
  },
  {
    path: PATH_NAME.HQ_USER_EDIT(null),
    component: HqUserEdit,
    guard: RolePermissionRouteGuard,
    title: 'title.editHQUsers',
    metaData: { title: 'title.editHQUsers' },
    permissions: [InternalPermission.UPDATE_HQ_USER],
    type: 'internal',
  },
  {
    path: PATH_NAME.HQ_USER_NEW,
    component: HqUserNew,
    guard: RolePermissionRouteGuard,
    title: 'title.newHQUsers',
    // breakLineTitle: ['HQ MANAGER', 'REGISTRATION'],
    metaData: { title: 'title.newHQUsers' },
    permissions: [InternalPermission.CREATE_HQ_USER],
    type: 'internal',
  },
  {
    path: PATH_NAME.HQ_USER,
    component: HqUser,
    guard: RolePermissionRouteGuard,
    title: 'title.listOfHQUsers',
    metaData: { title: 'title.listOfHQUsers' },
    permissions: [InternalPermission.VIEW_HQ_USER],
    type: 'internal',
  },
  {
    path: PATH_NAME.MANAGER_NEW,
    component: SystemManagerNew,
    roles: [Role.SuperAdmin, Role.Internal, Role.UserAdmin],
    guard: RolePermissionRouteGuard,
    title: 'title.managerRegistration',
    // breakLineTitle: ['MANAGER', 'REGISTRATION'],
    metaData: { title: 'title.managerRegistration' },
    permissions: [InternalPermission.SA_CREATE_MANAGER],
    type: 'internal',
  },
  {
    path: PATH_NAME.MANAGER_EDIT(),
    component: SystemManagerEdit,
    roles: [Role.SuperAdmin, Role.Internal, Role.UserAdmin],
    guard: RolePermissionRouteGuard,
    title: 'title.editManager',
    metaData: { title: 'title.editManager' },
    permissions: [InternalPermission.SA_UPDATE_MANAGER],
    type: 'internal',
  },
  {
    path: PATH_NAME.MANAGER,
    component: SystemManager,
    roles: [Role.SuperAdmin, Role.Internal, Role.UserAdmin],
    guard: RolePermissionRouteGuard,
    title: 'title.listOfManagers',
    metaData: { title: 'title.listOfManagers' },
    permissions: [InternalPermission.VIEW_HIRING_MANAGERS],
    type: 'internal',
  },
  // {
  //   path: PATH_NAME.MANAGER,
  //   component: ManagerList,
  //   roles: [Role.SuperAdmin],
  //   guard: RolePermissionRouteGuard,
  //   title: 'title.listOfManagers',
  //   metaData: { title: 'title.listOfManagers' },
  //   permissions: [InternalPermission.VIEW_HIRING_MANAGERS],
  //   type: 'internal',
  // },
  {
    path: PATH_NAME.APPLICANT_NEW,
    component: ApplicantNew,
    guard: RolePermissionRouteGuard,
    title: 'title.newApplicant',
    metaData: { title: 'title.newApplicant' },
    permissions: [InternalPermission.CREATE_APPLICANT],
    type: 'internal',
  },
  {
    path: PATH_NAME.APPLICANT_EDIT(),
    component: ApplicantEdit,
    guard: RolePermissionRouteGuard,
    title: 'title.editApplicant',
    metaData: { title: 'title.editApplicant' },
    permissions: [InternalPermission.UPDATE_APPLICANT],
    type: 'internal',
  },
  {
    path: PATH_NAME.APPLICANT,
    component: Applicant,
    guard: RolePermissionRouteGuard,
    title: 'title.listOfApplicants',
    metaData: { title: 'title.listOfApplicants' },
    permissions: [InternalPermission.VIEW_APPLICANT],
    type: 'internal',
  },
  {
    path: PATH_NAME.EDUCATION_INSTITUTE_NEW,
    component: EducationalInstituteNew,
    guard: RolePermissionRouteGuard,
    title: 'title.newEducationalInstitute',
    metaData: { title: 'title.newEducationalInstitute' },
    // breakLineTitle: ['NEW EDUCATIONAL', 'INSTITUTE'],
    permissions: [InternalPermission.CREATE_EDUCATIONAL_INSTITUTE],
    type: 'internal',
  },
  {
    path: PATH_NAME.EDUCATION_INSTITUTE_EDIT(null),
    component: EducationalInstituteEdit,
    guard: RolePermissionRouteGuard,
    title: 'title.editEducationalInstitute',
    metaData: { title: 'title.editEducationalInstitute' },
    // breakLineTitle: ['EDIT EDUCATIONAL', 'INSTITUTE'],
    permissions: [InternalPermission.UPDATE_EDUCATIONAL_INSTITUTE],
    type: 'internal',
  },
  {
    path: PATH_NAME.EDUCATION_INSTITUTE,
    component: EducationalInstitute,
    guard: RolePermissionRouteGuard,
    title: 'title.listOfEducationInstitutes',
    metaData: { title: 'title.listOfEducationInstitutes' },
    // breakLineTitle: ['LIST OF', 'EDUCATIONAL INSTITUTES'],
    permissions: [InternalPermission.VIEW_EDUCATIONAL_INSTITUTE],
    type: 'internal',
  },
  {
    path: PATH_NAME.BILLING_RECORD,
    component: BillingRecord,
    guard: RolePermissionRouteGuard,
    title: 'title.billingRecords',
    metaData: { title: 'title.billingRecords' },
    permissions: [InternalPermission.VIEW_BILLING_RECORD],
    type: 'internal',
  },
  {
    path: PATH_NAME.JOD_CREDIT_ASSIGN,
    component: CompanyAssignCredit,
    guard: RolePermissionRouteGuard,
    title: 'title.assignCredit',
    metaData: { title: 'title.assignCredit' },
    permissions: [InternalPermission.ASSIGN_CREDIT],
    type: 'internal',
  },
  {
    path: PATH_NAME.JOD_CREDIT,
    component: CompanyCreditHistory,
    guard: RolePermissionRouteGuard,
    title: 'title.creditHistory',
    metaData: { title: 'title.creditHistory' },
    permissions: [InternalPermission.VIEW_COMPANY_CREDIT_HISTORY],
    type: 'internal',
  },
  {
    path: PATH_NAME.JOD_DEBIT_CREDIT,
    component: CompanyDebitCreditHistory,
    guard: RolePermissionRouteGuard,
    title: 'title.debitCreditHistory',
    metaData: { title: 'title.debitCreditHistory' },
    permissions: [InternalPermission.VIEW_COMPANY_DEBIT_CREDIT_HISTORY],
    type: 'internal',
  },
  {
    path: PATH_NAME.LOCATION,
    component: SystemLocations,
    guard: RolePermissionRouteGuard,
    title: 'title.listOfLocations',
    metaData: { title: 'title.listOfLocations' },
    permissions: [InternalPermission.VIEW_LOCATION],
    type: 'internal',
  },
  {
    path: PATH_NAME.PAYMENT_SUMMARY,
    component: PaymentSummary,
    guard: RolePermissionRouteGuard,
    title: 'title.jobSummary',
    metaData: { title: 'title.jobSummary' },
    permissions: [InternalPermission.VIEW_PAYMENT_SUMMARY],
    type: 'internal',
  },
  {
    path: PATH_NAME.PAYMENT_PROCESSED,
    component: PaymentProcessed,
    guard: RolePermissionRouteGuard,
    title: 'title.jobPayments',
    metaData: { title: 'title.jobPayments' },
    permissions: [InternalPermission.VIEW_PAYMENT_PROCESSED],
    type: 'internal',
  },
  {
    path: PATH_NAME.PAYMENT_ADJUSTMENTS,
    component: PaymentAdjustments,
    guard: RolePermissionRouteGuard,
    title: 'title.paymentAdjustments',
    metaData: { title: 'title.paymentAdjustments' },
    permissions: [InternalPermission.VIEW_PAYMENT_ADJUSTMENT_APPROVAL],
    type: 'internal',
  },
  {
    path: PATH_NAME.PAYMENT_FUTURE,
    component: PaymentFuture,
    guard: RolePermissionRouteGuard,
    title: 'title.futurePayments',
    metaData: { title: 'title.futurePayments' },
    permissions: [InternalPermission.VIEW_PAYMENT_FUTURE],
    type: 'internal',
  },
  {
    path: PATH_NAME.REPORT,
    component: ReportList,
    guard: RolePermissionRouteGuard,
    title: 'title.report',
    metaData: { title: 'title.report' },
    permissions: [InternalPermission.VIEW_REPORT],
    type: 'internal',
  },
  {
    path: PATH_NAME.INTERNAL_USER_EDIT(),
    component: InternalUserEdit,
    guard: RolePermissionRouteGuard,
    title: 'title.editInternalUser',
    metaData: { title: 'title.editInternalUser' },
    permissions: [InternalPermission.UPDATE_INTERNAL_USER],
    type: 'internal',
  },
  {
    path: PATH_NAME.SUPERHQ_USER_EDIT(),
    component: SuperHqUserEdit,
    guard: RolePermissionRouteGuard,
    title: 'title.editInternalUser',
    metaData: { title: 'title.editInternalUser' },
    permissions: [InternalPermission.UPDATE_SUPER_HQ],
    type: 'internal',
  },
  {
    path: PATH_NAME.INTERNAL_USER_NEW,
    component: InternalUserNew,
    guard: RolePermissionRouteGuard,
    title: 'button.addNewUser',
    metaData: { title: 'button.addNewUser' },
    permissions: [InternalPermission.CREATE_INTERNAL_USER],
    type: 'internal',
  },
  {
    path: PATH_NAME.SUPERHQ_USER_NEW,
    component: SuperHqUserNew,
    guard: RolePermissionRouteGuard,
    title: 'button.addNewUser',
    metaData: { title: 'button.addNewUser' },
    permissions: [InternalPermission.CREATE_SUPER_HQ],
    type: 'internal',
  },
  {
    path: PATH_NAME.INTERNAL_USER,
    component: InternalUser,
    guard: RolePermissionRouteGuard,
    title: 'title.users',
    metaData: { title: 'title.users' },
    permissions: [InternalPermission.VIEW_INTERNAL_USER],
    type: 'internal',
  },
  {
    path: PATH_NAME.SUPERHQ_USER,
    component: SuperHqUser,
    guard: RolePermissionRouteGuard,
    title: 'title.superHqUsers',
    metaData: { title: 'title.superHqUsers' },
    permissions: [InternalPermission.LIST_SUPER_HQ],
    type: 'internal',
  },
  {
    path: PATH_NAME.INTERNAL_ROLE_NEW,
    component: UserRoleNew,
    guard: RolePermissionRouteGuard,
    title: 'title.newRole',
    metaData: { title: 'title.newRole' },
    permissions: [InternalPermission.CREATE_ROLE],
    type: 'internal',
  },
  {
    path: PATH_NAME.INTERNAL_ROLE_EDT(),
    component: UserRoleEdit,
    guard: RolePermissionRouteGuard,
    title: 'title.editRole',
    metaData: { title: 'title.editRole' },
    permissions: [InternalPermission.UPDATE_ROLE],
    type: 'internal',
  },
  {
    path: PATH_NAME.PARTNER_ROLE_EDT(),
    component: UserPartnerRoleEdit,
    guard: RolePermissionRouteGuard,
    title: 'title.editRole',
    metaData: { title: 'title.editRole' },
    permissions: [InternalPermission.UPDATE_ROLE],
    type: 'internal',
  },
  {
    path: PATH_NAME.INTERNAL_ROLE,
    component: UserRole,
    guard: RolePermissionRouteGuard,
    title: 'title.roles',
    metaData: { title: 'title.roles' },
    permissions: [InternalPermission.VIEW_ROLE],
    type: 'internal',
  },
  {
    path: PATH_NAME.PERMISSION,
    component: Permission,
    guard: RolePermissionRouteGuard,
    title: 'label.permission',
    metaData: { title: 'label.permission' },
    permissions: [InternalPermission.VIEW_PERMISSION],
    type: 'internal',
  },
  {
    path: PATH_NAME.CONFIGURATION_NOTIFICATIONS,
    component: NotificationConfiguration,
    guard: RolePermissionRouteGuard,
    title: 'title.notificationConfigurations',
    metaData: { title: 'title.notificationConfigurations' },
    permissions: [
      InternalPermission.VIEW_NOTIFICATION_CONFIGURATION,
      InternalPermission.UPDATE_NOTIFICATION_CONFIGURATION
    ],
    type: 'internal',
  },
  {
    path: PATH_NAME.CONFIGURATION_JOD_BADGE,
    component: BadgeConfiguration,
    guard: RolePermissionRouteGuard,
    title: 'title.jodBadgesConfiguration',
    metaData: { title: 'title.jodBadgesConfiguration' },
    permissions: [
      InternalPermission.VIEW_JOD_BADGE_CONFIGURATION,
      InternalPermission.UPDATE_JOD_BADGE_CONFIGURATION
    ],
    type: 'internal',
  },
  {
    path: PATH_NAME.CONFIGURATION_OTHER,
    component: OtherConfiguration,
    guard: RolePermissionRouteGuard,
    title: 'title.otherConfigurations',
    metaData: { title: 'title.otherConfigurations' },
    permissions: [
      InternalPermission.VIEW_OTHER_CONFIGURATION,
      InternalPermission.UPDATE_OTHER_CONFIGURATION
    ],
    type: 'internal',
  },
  {
    path: PATH_NAME.CONFIGURATION_APP,
    component: AppConfiguration,
    guard: RolePermissionRouteGuard,
    title: 'title.appConfig',
    metaData: { title: 'title.appConfig' },
    permissions: [
      InternalPermission.VIEW_APP_BANNER_CONFIGURATION,
      InternalPermission.UPDATE_APP_BANNER_CONFIGURATION
    ],
    type: 'internal',
  },
  {
    path: PATH_NAME.SELECT_2FA_METHOD,
    exact: true,
    guard: RolePermissionRouteGuard,
    component: Select2faMethod,
    excludeMainLayout: true,
    type: 'internal',
  },
  {
    path: PATH_NAME.AUTH_OTP_QR,
    exact: true,
    guard: RolePermissionRouteGuard,
    component: AuthOtpQr,
    excludeMainLayout: true,
    type: 'internal',
  },
  {
    path: PATH_NAME.AUTH_OTP,
    exact: true,
    guard: RolePermissionRouteGuard,
    component: AuthOtp,
    excludeMainLayout: true,
    type: 'internal',
  },
  {
    path: PATH_NAME.SUCCESSFUL_LOGIN,
    exact: true,
    guard: RolePermissionRouteGuard,
    component: SuccessfulLogin,
    excludeMainLayout: true,
    type: 'internal',
  },
  {
    path: PATH_NAME.CERTIFICATE,
    component: Certificate,
    guard: RolePermissionRouteGuard,
    title: 'title.certificates',
    metaData: { title: 'title.certificates' },
    permissions: [
      InternalPermission.VIEW_CERTIFICATES,
      InternalPermission.ENABLE_DISABLE_CERTIFICATE,
    ],
    type: 'internal',
  },
  {
    path: PATH_NAME.SLOT_USER_VIEW,
    component: SlotUserList,
    guard: RolePermissionRouteGuard,
    title: 'title.slotUser',
    metaData: { title: 'title.slotUser' },
    permissions: [InternalPermission.VIEW_SLOT_USER],
    type: 'internal',
  },
  {
    path: PATH_NAME.SLOT_USER_EDIT(),
    component: SlotUserEdit,
    guard: RolePermissionRouteGuard,
    title: 'title.slotUser',
    metaData: { title: 'title.slotUser' },
    permissions: [InternalPermission.VIEW_SLOT_USER],
    type: 'internal',
  },
];

export const partnerRoutes: RouteConfig[] = [
  {
    path: PATH_NAME.ACCOUNT_SETTINGS,
    component: AccountSettings,
    title: 'title.editProfile',
    metaData: { title: 'title.editProfile' },
    type: 'all',
  },
  {
    path: PATH_NAME.DASHBOARD,
    exact: true,
    title: 'title.dashboard',
    guard: RolePermissionRouteGuard,
    roleComponents: {
      [Role.HqManager]: HqAdminDashboard,
      [Role.AreaManager]: AreaAdminViewDashboard,
      [Role.LocationManager]: LocationAdminViewDashboard,
    },
    permissions: [
      PartnerPermission.VIEW_JOB_OVERVIEW,
      PartnerPermission.VIEW_HIRING_OVERVIEW,
      PartnerPermission.VIEW_CREDIT_OVERVIEW,
      PartnerPermission.VIEW_RATING_OVERVIEW,
      PartnerPermission.DOWNLOAD_SERVICE_CONTRACT,
    ],
    type: 'partner',
  },
  {
    path: PATH_NAME.JOB_TEMPLATE_NEW,
    component: JobTemplateNew,
    guard: RolePermissionRouteGuard,
    title: 'title.addNewJobTemplate',
    metaData: { title: 'title.addNewJobTemplate' },
    permissions: [PartnerPermission.CREATE_JOB_TEMPLATE],
    type: 'partner',
  },
  {
    path: PATH_NAME.JOB_TEMPLATE_EDIT(),
    component: JobTemplateEdit,
    guard: RolePermissionRouteGuard,
    title: 'title.editJobTemplate',
    metaData: { title: 'title.editJobTemplate' },
    permissions: [PartnerPermission.UPDATE_JOB_TEMPLATE],
    type: 'partner',
  },
  {
    path: PATH_NAME.JOB_TEMPLATE,
    component: JobTemplateList,
    guard: RolePermissionRouteGuard,
    title: 'title.jobTemplates',
    metaData: { title: 'title.jobTemplates' },
    permissions: [PartnerPermission.VIEW_JOB_TEMPLATE],
    type: 'partner',
  },
  {
    path: PATH_NAME.MANAGER_NEW,
    component: ManagerNew,
    roles: [Role.HqManager],
    guard: RolePermissionRouteGuard,
    title: 'title.managerRegistration',
    // breakLineTitle: ['MANAGER', 'REGISTRATION'],
    metaData: { title: 'title.managerRegistration' },
    permissions: [PartnerPermission.CREATE_MANAGER],
    type: 'partner',
  },
  {
    path: PATH_NAME.MANAGER_EDIT(),
    component: ManagerEdit,
    roles: [Role.HqManager],
    guard: RolePermissionRouteGuard,
    title: 'title.editManager',
    metaData: { title: 'title.editManager' },
    permissions: [PartnerPermission.UPDATE_MANAGER],
    type: 'partner',
  },
  {
    path: PATH_NAME.MANAGER,
    component: ManagerList,
    roles: [Role.HqManager],
    guard: RolePermissionRouteGuard,
    title: 'title.listOfManagers',
    metaData: { title: 'title.listOfManagers' },
    permissions: [PartnerPermission.VIEW_MANAGER],
    type: 'partner',
  },
  {
    path: PATH_NAME.HQ_ROLE,
    component: HqUserRole,
    roles: [Role.HqManager, Role.SuperHqInternal, Role.SuperHqExternal],
    guard: RolePermissionRouteGuard,
    title: 'title.roles',
    metaData: { title: 'title.roles' },
    permissions: [PartnerPermission.VIEW_ROLE],
    type: 'partner',
  },
  {
    path: PATH_NAME.HQ_ROLE_EDT(),
    component: HqUserRoleEdit,
    roles: [Role.HqManager, Role.SuperHqInternal, Role.SuperHqExternal],
    guard: RolePermissionRouteGuard,
    title: 'title.editRole',
    metaData: { title: 'title.editRole' },
    permissions: [PartnerPermission.UPDATE_ROLE],
    type: 'partner',
  },
  {
    path: PATH_NAME.HQ_PERMISSION,
    component: HqPermission,
    roles: [Role.HqManager, Role.SuperHqInternal, Role.SuperHqExternal],
    guard: RolePermissionRouteGuard,
    title: 'label.permission',
    metaData: { title: 'label.permission' },
    permissions: [PartnerPermission.VIEW_PERMISSION],
    type: 'partner',
  },
  {
    path: PATH_NAME.LOCATION_NEW,
    component: LocationNew,
    roles: [Role.HqManager],
    guard: RolePermissionRouteGuard,
    title: 'title.addNewLocation',
    metaData: { title: 'title.addNewLocation' },
    permissions: [PartnerPermission.CREATE_LOCATION],
    type: 'partner',
  },
  {
    path: PATH_NAME.LOCATION_EDIT(),
    component: LocationEdit,
    roles: [Role.HqManager],
    guard: RolePermissionRouteGuard,
    title: 'title.editLocation',
    metaData: { title: 'title.editLocation' },
    permissions: [PartnerPermission.UPDATE_LOCATION],
    type: 'partner',
  },
  {
    path: PATH_NAME.LOCATION_FEEDBACK(),
    roleComponents: {
      [Role.HqManager]: LocationFeedbacks,
      [Role.AreaManager]: AreaLocationFeedbacks,
    },
    roles: [Role.HqManager, Role.AreaManager],
    guard: RolePermissionRouteGuard,
    title: 'title.locationFeedback',
    // breakLineTitle: ['LOCATION', 'FEEDBACK'],
    metaData: { title: 'title.locationFeedback' },
    permissions: [PartnerPermission.VIEW_LOCATION_FEEDBACK],
    type: 'partner',
  },
  {
    path: PATH_NAME.LOCATION,
    roles: [Role.HqManager, Role.AreaManager],
    guard: RolePermissionRouteGuard,
    roleComponents: {
      [Role.HqManager]: CompanyLocations,
      [Role.AreaManager]: AreaLocations,
    },
    title: 'title.listOfLocations',
    metaData: { title: 'title.listOfLocations' },
    permissions: [PartnerPermission.VIEW_LOCATION],
    type: 'partner',
  },
  {
    path: PATH_NAME.CANDIDATE_JOB(),
    component: JobApplicant,
    guard: RolePermissionRouteGuard,
    title: '',
    metaData: { title: 'title.candidatesOfJob' },
    permissions: [PartnerPermission.VIEW_CANDIDATE],
    type: 'partner',
  },
  {
    path: PATH_NAME.CANDIDATE,
    component: JobApplicant,
    guard: RolePermissionRouteGuard,
    title: 'title.listOfAllCandidates',
    metaData: { title: 'title.listOfAllCandidates' },
    // breakLineTitle: ['LIST OF', 'ALL CANDIDATES'],
    permissions: [PartnerPermission.VIEW_CANDIDATE],
    type: 'partner',
  },
  {
    path: PATH_NAME.JOB_NEW,
    component: JobNew,
    guard: RolePermissionRouteGuard,
    title: 'title.createNewJob',
    metaData: { title: 'title.createNewJob' },
    permissions: [PartnerPermission.CREATE_JOB],
    type: 'partner',
  },
  {
    path: PATH_NAME.JOB_COPY(),
    component: JobCopy,
    guard: RolePermissionRouteGuard,
    title: 'title.copyJob',
    metaData: { title: 'title.copyJob' },
    permissions: [PartnerPermission.CREATE_JOB],
    type: 'partner',
  },
  {
    path: PATH_NAME.JOB_EDIT(),
    component: JobEdit,
    guard: RolePermissionRouteGuard,
    title: 'title.jobEdit',
    metaData: { title: 'title.jobEdit' },
    permissions: [PartnerPermission.CREATE_JOB],
    type: 'partner',
  },
  {
    path: PATH_NAME.JOB,
    component: AllJobPosting,
    guard: RolePermissionRouteGuard,
    title: 'title.allJobPosting',
    metaData: { title: 'title.allJobPosting' },
    permissions: [PartnerPermission.VIEW_JOB],
    type: 'partner',
  },
  {
    path: PATH_NAME.ALL_JOBS,
    component: SuperHQJobPosting,
    guard: RolePermissionRouteGuard,
    title: 'title.allJobPosting',
    metaData: { title: 'title.allJobPosting' },
    permissions: [InternalPermission.VIEW_JOB_POSTING],
    type: 'internal',
  },
  {
    path: PATH_NAME.JOB_CANCEL,
    component: JobCancel,
    roles: [Role.HqManager],
    guard: RolePermissionRouteGuard,
    title: 'title.cancelJobs',
    metaData: { title: 'title.cancelJobs' },
    permissions: [PartnerPermission.CANCEL_MULTIPLE_JOB],
    type: 'partner',
  },
  {
    path: PATH_NAME.JOB_BULK_UPDATE,
    component: JobBulkUpdate,
    roles: [Role.HqManager],
    guard: RolePermissionRouteGuard,
    title: 'title.updateJobs',
    metaData: { title: 'title.updateJobs' },
    permissions: [PartnerPermission.BULK_UPDATE_JOB_WAGE],
    type: 'partner',
  },
  {
    path: PATH_NAME.HOW_TO_POST_JOBS,
    component: HowToPost,
    roles: [Role.HqManager, Role.AreaManager, Role.LocationManager],
    guard: RolePermissionRouteGuard,
    title: 'title.howToPostJobs',
    metaData: { title: 'title.howToPostJobs' },
    permissions: [PartnerPermission.VIEW_POST_JOBS],
    type: 'partner',
  },
  {
    path: PATH_NAME.HOW_TO_MAKE_SELECTIONS,
    component: HowToSelections,
    roles: [Role.HqManager, Role.AreaManager, Role.LocationManager],
    guard: RolePermissionRouteGuard,
    title: 'title.howToMakeSelections',
    metaData: { title: 'title.howToMakeSelections' },
    permissions: [PartnerPermission.VIEW_MAKE_SELECTIONS],
    type: 'partner',
  },
  {
    path: PATH_NAME.HOW_TO_CLOCK_IN_AND_OUT,
    component: HowToClock,
    roles: [Role.HqManager, Role.AreaManager, Role.LocationManager],
    guard: RolePermissionRouteGuard,
    title: 'title.howToClockInAndOut',
    metaData: { title: 'title.howToClockInAndOut' },
    permissions: [PartnerPermission.VIEW_CLOCK_IN_AND_OUT],
    type: 'partner',
  },

  {
    path: PATH_NAME.LOCATION_PROFILE_FEEDBACK,
    component: LocationProfileFeedback,
    title: 'title.locationFeedback',
    metaData: { title: 'title.locationFeedback' },
    roles: [Role.LocationManager],
    guard: RolePermissionRouteGuard,
    permissions: [PartnerPermission.VIEW_LOCATION_FEEDBACK],
    type: 'partner',
  },
  {
    path: PATH_NAME.LOCATION_PROFILE,
    component: LocationProfile,
    title: 'title.locationProfile',
    metaData: { title: 'title.locationProfile' },
    roles: [Role.LocationManager],
    guard: RolePermissionRouteGuard,
    permissions: [PartnerPermission.VIEW_LOCATION],
    type: 'partner',
  },
  {
    path: PATH_NAME.JOB_HISTORY,
    component: JobHistory,
    roles: [Role.AreaManager, Role.HqManager, Role.LocationManager],
    guard: RolePermissionRouteGuard,
    title: 'title.jobHistory',
    metaData: { title: 'title.jobHistory' },
    permissions: [PartnerPermission.VIEW_JOB_HISTORY],
    type: 'partner',
  },
  {
    path: PATH_NAME.JOD_CREDIT,
    roles: [Role.HqManager, Role.AreaManager, Role.LocationManager],
    roleComponents: {
      [Role.HqManager]: JodCreditInCompany,
      [Role.AreaManager]: JodCreditInArea,
      [Role.LocationManager]: JodCreditInLocation,
    },
    guard: RolePermissionRouteGuard,
    title: 'title.jodCredits',
    metaData: { title: 'title.jodCredits' },
    permissions: [PartnerPermission.VIEW_CREDIT_HISTORY, PartnerPermission.VIEW_LOCATION_CREDIT],
    type: 'partner',
  },
  {
    path: PATH_NAME.PAYMENT_ADJUSTMENT_APPROVAL(),
    component: PaymentApproval,
    guard: RolePermissionRouteGuard,
    title: 'title.paymentApproval',
    metaData: { title: 'title.paymentApproval' },
    permissions: [
      InternalPermission.VIEW_PAYMENT_ADJUSTMENT_APPROVAL,
      InternalPermission.CONFIRM_PAYMENT_ADJUSTMENT_APPROVAL,
    ],
    type: 'internal',
  },
  {
    path: PATH_NAME.DASHBOARD,
    exact: true,
    title: 'title.redirecting',
    component: RedirectFirstPage,
  },
  {
    path: '/demo',
    component: Demo,
    metaData: { title: 'Demo' },
    title: 'Demo',
  },
  {
    path: '*',
    component: Error404,
    metaData: { title: '404 Page Not Found' },
  },
  {
    path: PATH_NAME.SELECT_2FA_METHOD,
    exact: true,
    guard: RolePermissionRouteGuard,
    component: Select2faMethod,
    excludeMainLayout: true,
    type: 'partner',
  },
  {
    path: PATH_NAME.AUTH_OTP_QR,
    exact: true,
    guard: RolePermissionRouteGuard,
    component: AuthOtpQr,
    excludeMainLayout: true,
    type: 'partner',
  },
  {
    path: PATH_NAME.AUTH_OTP,
    exact: true,
    guard: RolePermissionRouteGuard,
    component: AuthOtp,
    excludeMainLayout: true,
    type: 'partner',
  },
  {
    path: PATH_NAME.SUCCESSFUL_LOGIN,
    exact: true,
    guard: RolePermissionRouteGuard,
    component: SuccessfulLogin,
    excludeMainLayout: true,
    type: 'partner',
  },
];

export const superHqRoutes: RouteConfig[] = [
  {
    path: PATH_NAME.DASHBOARD,
    exact: true,
    guard: RolePermissionRouteGuard,
    roles: [Role.SuperHqInternal, Role.SuperHqExternal],
    title: 'title.superHqSelectCompany',
    metaData: { title: 'title.superHqSelectCompany' },
    roleComponents: {
      [Role.SuperHqInternal]: SuperHqToggleView,
      [Role.SuperHqExternal]: SuperHqToggleView,
    },
    type: 'superHq',
    excludeMainLayout: true,
  },
  {
    path: PATH_NAME.SELECT_2FA_METHOD,
    exact: true,
    guard: RolePermissionRouteGuard,
    component: Select2faMethod,
    excludeMainLayout: true,
    type: 'superHq',
  },
  {
    path: PATH_NAME.AUTH_OTP_QR,
    exact: true,
    guard: RolePermissionRouteGuard,
    component: AuthOtpQr,
    excludeMainLayout: true,
    type: 'superHq',
  },
  {
    path: PATH_NAME.AUTH_OTP,
    exact: true,
    guard: RolePermissionRouteGuard,
    component: AuthOtp,
    excludeMainLayout: true,
    type: 'superHq',
  },
  {
    path: PATH_NAME.SUCCESSFUL_LOGIN,
    exact: true,
    guard: RolePermissionRouteGuard,
    component: SuccessfulLogin,
    excludeMainLayout: true,
    type: 'superHq',
  },
];

export const privateRoutes: RouteConfig[] = concat(internalRoutes, partnerRoutes, superHqRoutes);
