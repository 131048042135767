export enum InternalPermission {
  CHANGE_DASHBOARD_LOGO = 1,

  VIEW_COMPANY = 2,
  CREATE_COMPANY = 3,
  UPDATE_COMPANY = 4,
  ENABLE_DISABLE_COMPANY = 5,
  DOWNLOAD_COMPANY_LIST = 6,
  ASSIGN_CREDIT = 7,
  VIEW_COMPANY_CREDIT_HISTORY = 8,
  DOWNLOAD_COMPANY_CREDIT_HISTORY = 9,

  VIEW_JOB_TYPE = 10,
  CREATE_JOB_TYPE = 11,
  UPDATE_JOB_TYPE = 12,
  ENABLE_DISABLE_JOB_TYPE = 13,

  VIEW_HQ_USER = 14,
  CREATE_HQ_USER = 15,
  UPDATE_HQ_USER = 16,
  ENABLE_DISABLE_HQ_USER = 17,

  VIEW_COMPANY_DEBIT_CREDIT_HISTORY = 18,
  DOWNLOAD_COMPANY_DEBIT_CREDIT_HISTORY = 19,

  VIEW_MANAGER = 92,
  CREATE_MANAGER = 93,
  UPDATE_MANAGER = 94,
  ENABLE_DISABLE_MANAGER = 95,

  VIEW_APPLICANT = 18,
  CREATE_APPLICANT = 19,
  UPDATE_APPLICANT = 20,
  ENABLE_DISABLE_APPLICANT = 21,
  VERIFY_IDENTITY_DOCUMENT = 22,
  VIEW_APPLICANT_SLOT_HISTORY = 23,
  DOWNLOAD_JOB_SUMMARY = 88,

  VIEW_EDUCATIONAL_INSTITUTE = 24,
  CREATE_EDUCATIONAL_INSTITUTE = 25,
  UPDATE_EDUCATIONAL_INSTITUTE = 26,
  ENABLE_DISABLE_EDUCATIONAL_INSTITUTE = 27,

  VIEW_LOCATION = 28,

  VIEW_BILLING_RECORD = 29,

  VIEW_PAYMENT_SUMMARY = 30,
  VIEW_PAYMENT_PROCESSED = 31,
  VIEW_PAYMENT_FUTURE = 32,
  DOWNLOAD_JOB_PAYMENT = 33,
  BANK_PROCESS = 34,
  PROCESS_PAYMENT = 35,
  PROCESS_MULTIPLE_PAYMENT = 36,

  VIEW_REPORT = 37,
  DOWNLOAD_REPORT = 38,

  VIEW_INTERNAL_USER = 39,
  CREATE_INTERNAL_USER = 40,
  UPDATE_INTERNAL_USER = 41,
  ENABLE_DISABLE_INTERNAL_USER = 42,

  CREATE_ROLE = 44,
  ENABLE_DISABLE_ROLE = 46,
  UPDATE_ROLE = 45,
  VIEW_ROLE = 43,
  VIEW_PERMISSION = 47,

  VIEW_NOTIFICATION_CONFIGURATION = 48,
  UPDATE_NOTIFICATION_CONFIGURATION = 49,
  VIEW_OTHER_CONFIGURATION = 50,
  UPDATE_OTHER_CONFIGURATION = 51,

  CONFIRM_PAYMENT_APPROVAL = 52,

  VIEW_JOD_BADGE_CONFIGURATION = 96,
  UPDATE_JOD_BADGE_CONFIGURATION = 97,

  VIEW_HIRING_MANAGERS = 98,
  SA_CREATE_MANAGER = 99,
  SA_UPDATE_MANAGER = 100,
  SA_ENABLE_DISABLE_MANAGER = 101,

  VIEW_PAYMENT_ADJUSTMENT_APPROVAL = 103,
  CREATE_PAYMENT_ADJUSTMENT_APPROVAL = 104,
  CONFIRM_PAYMENT_ADJUSTMENT_APPROVAL = 105,

  VIEW_JOB_POSTING = 71,

  VIEW_CERTIFICATES = 106,
  CREATE_CERTIFICATE = 107,
  UPDATE_CERTIFICATE = 108,
  ENABLE_DISABLE_CERTIFICATE = 109,

  VIEW_SLOT_USER = 122,
  EDIT_SLOT_USER = 123,
  
  CREATE_SUPER_HQ = 116,
  UPDATE_SUPER_HQ = 117,
  LIST_SUPER_HQ = 118,
  ENABLE_DISABLE_SUPERHQ_USER = 119,

  VIEW_APP_BANNER_CONFIGURATION = 120,
  UPDATE_APP_BANNER_CONFIGURATION = 121,
}

export enum PartnerPermission {
  DOWNLOAD_SERVICE_CONTRACT = 52,
  VIEW_JOB_OVERVIEW = 53,
  VIEW_HIRING_OVERVIEW = 54,
  VIEW_CREDIT_OVERVIEW = 55,
  VIEW_RATING_OVERVIEW = 56,

  VIEW_JOB = 71,
  CREATE_JOB = 72,
  UPDATE_JOB = 73,
  DELETE_JOB = 74,
  APPROVE_REJECT_PENDING_JOB = 75,
  VIEW_APPLIED_APPLICANT = 76,
  APPROVE_APPLICANT = 77,
  CANCEL_APPLICANT = 78,
  CLOCK_IN_CLOCK_OUT_APPLICANT_SLOT = 79,
  VIEW_JOB_HISTORY = 80,
  DOWNLOAD_JOB_HISTORY = 81,
  BULK_UPDATE_JOB_WAGE = 82,
  CANCEL_MULTIPLE_JOB = 83,

  VIEW_CANDIDATE = 84,

  VIEW_LOCATION_CREDIT = 85,
  VIEW_CREDIT_HISTORY = 86,
  VIEW_LOCATION = 57,
  VIEW_LOCATION_FEEDBACK = 58,
  CREATE_LOCATION = 59,
  UPDATE_LOCATION = 60,
  ENABLE_DISABLE_LOCATION = 61,
  ASSIGN_CREDIT = 62,

  DOWNLOAD_CREDIT_HISTORY = 87,

  VIEW_MANAGER = 63,
  CREATE_MANAGER = 64,
  UPDATE_MANAGER = 65,
  ENABLE_DISABLE_MANAGER = 66,

  VIEW_JOB_TEMPLATE = 67,
  CREATE_JOB_TEMPLATE = 68,
  UPDATE_JOB_TEMPLATE = 69,
  ENABLE_DISABLE_JOB_TEMPLATE = 70,

  VIEW_POST_JOBS = 89,
  VIEW_MAKE_SELECTIONS = 90,
  VIEW_CLOCK_IN_AND_OUT = 91,

  // Added Permissions
  ENABLE_DISABLE_ROLE = 46,
  UPDATE_ROLE = 45,
  VIEW_ROLE = 43,
  VIEW_PERMISSION = 47,
}

export type SystemPermission = InternalPermission | PartnerPermission;
