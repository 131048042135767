import * as Sentry from '@sentry/react';
import clevertap from 'clevertap-web-sdk';
import React from 'react';
import ReactDOM from 'react-dom';

import 'configs/dayjs';
import 'configs/i18n';

// import * as serviceWorker from '../public/service-worker';
import { App } from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT || '';
const traceTargets = ['localhost', apiEndpoint];
const CT = process.env.REACT_APP_CT || '';
clevertap.init(CT, 'sg1');
clevertap.setLogLevel(3);
clevertap.privacy.push({ optOut: false }); //call the flag to true, if the user of the device opts out of sharing their data
clevertap.privacy.push({ useIP: true }); //call the flag to true, if the user agrees to share their IP data

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: traceTargets,
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// serviceWorker.register();
